import * as React from "react"
import styled from '@emotion/styled'
import Layout from '../components/Layout/Layout';
import { PageTitleHeader } from '../globalStyles';

const Container = styled.div`
  margin: 3rem auto;
  max-width: 960px;
  padding: 0 20px;

  iframe {
    width: 100%;
    height: 1200px;
  }

  h2, h3, h4 {
    font-weight: 300;
  }

  h2 {
    font-size: 2.25rem;
  }
  h3 {
    font-size: 1.8rem;
  }
  h4 {
    font-size: 1.5rem;
  }
`

const Classes = () => {
  return(
  <Layout
    title="Classes"
    noIndex={true}
  >   
    <Container>
      <PageTitleHeader> Classes </PageTitleHeader>

      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSehKgVkIEZyTMXP6_BaLeXkunegj4mPR1ZmH99nCVU6kYrtUA/viewform?embedded=true" width="640" height="1074" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

      </Container>
  
    </Layout>
  )
}

export default Classes

